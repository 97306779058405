.navigation{
    position: fixed;
    z-index: 2;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 calc((100% - 1200px) / 2);
    box-sizing: border-box;
    background: transparent;
    transition: .4s all ease;
}

.navigation.scrolled{
    background: var(--color-white);
    box-shadow: rgba(12, 0, 46, 0.06) 0 4px 6px 0;
}

.logo{
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoText{
    font-size: 32px;
    font-weight: bold;
    color: var(--color-white);
    margin-left: 15px;
    transition: .2s font-size linear;
}

.logoImageWrapper svg{
    transition: .2s all linear;
}

.logoImageWrapper svg{
    width: 48px;
    height: auto;
}

.logoImageWrapper svg path{
    fill: var(--color-white);
}

.actionsWrapper{
    display: flex;
    align-items: center;
}

.linksContainer{
    display: flex;
    align-items: center;
}

.navLink{
    display: block;
    padding: 45px 10px;
    margin: 0 10px;
    font-weight: 400;
    color: var(--color-white);
    transition: .2s padding linear, .3s color ease;
    cursor: pointer;
    text-decoration: none;
}

.navLink .linkTitle{
    position: relative;
}

.navLink .linkTitle::after{
    transition: .3s all ease-in-out;
    content: '';
    position: absolute;
    background: var(--color-white);
    height: 1px;
    bottom: -5px;
    right: 0;
    width: 0;
}


.contactWrapper{
    padding-left: 20px;
    border-left: 1px solid var(--color-white);
    display: flex;
    align-items: center;
}

.contactButton{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s all ease;
    position: relative;
}

.contactButton svg{
    width: 18px;
    margin-right: 8px;
}

.contactButton svg path{
    fill: var(--color-white);
    transition: .3s fill ease;
}

.contactButton .hoverBg{
    border-radius: 4px;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -5px;
    right: -5px;
    transition: .2s all linear;
}

.contactText{
    color: var(--color-white);
    transition: .3s color ease;
}

.contactText, .contactButton svg{
    position: relative;
    z-index: 2;
}

.scrolled .navLink{
    color: var(--color-blue);
    font-weight: 600;
    padding: 35px 10px;
}

.scrolled .navLink .linkTitle::after{
    background: var(--color-accent);
}

.scrolled .logoImageWrapper svg{
    width: 40px;
}

.scrolled .logoImageWrapper svg path{
    fill: var(--color-accent);
}

.scrolled .logoText{
    color: var(--color-accent);
    font-size: 24px;
    margin-left: 10px;
}

.scrolled .contactWrapper{
    border-color: var(--color-blue);
}

.scrolled .contactWrapper svg path{
    fill: var(--color-accent);
}

.scrolled .contactText{
    color: var(--color-accent);
    font-weight: bold;
}

.menuToggle{
    display: none;
}

@media (min-width: 769px){

    .scrolled .navLink:hover{
        color: var(--color-accent);
    }

    .navLink:hover{
        color: white;
    }

    .navLink:hover .linkTitle::after{
        left: 0;
        width: 100%;
    }

    .contactButton:hover .contactText{
        color: var(--color-accent);
    }

    .contactButton:hover .hoverBg{
        background: var(--color-white);
        left: -10px;
        right: -10px;
        top: -5px;
        bottom: -5px;
    }

    .contactButton:hover svg path{
        fill: var(--color-accent);
    }

    .scrolled .contactButton:hover .contactText{
        color: var(--color-white)
    }

    .scrolled .contactButton:hover .hoverBg{
        background: var(--color-accent);
    }

    .scrolled .contactButton:hover svg path{
        fill: var(--color-white);
    }
}

@media(max-width: 1280px){
    .navigation{
        padding: 0 40px;
    }
}

@media (max-width: 768px) {
    .navigation{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .mobileExpanded.navigation{
        top: 0;
        bottom: 0;
    }
    .scrolled.navigation{
        background: transparent;
    }

    .scrolled .mobilePanel{
        background: var(--color-white);
        padding: 10px 20px;
    }

    .mobilePanel{
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        transition: .2s all ease;
    }

    .mobileExpanded .mobilePanel{
        background: var(--color-white);
        padding: 40px 20px;
    }

    .mobileExpanded .logoImageWrapper{
        width: 64px;
    }

    .mobileExpanded .logoImageWrapper svg path{
        fill: var(--color-accent);
    }

    .menuToggle{
        display: block;
    }

    .logoText{
        display: none;
    }

    .actionsWrapper{
        position: absolute;
        flex-grow: 1;
        opacity: 0;
        pointer-events: none;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .mobileExpanded .actionsWrapper{
        position: static;
        opacity: 1;
        background: var(--color-white);
        pointer-events: all;
        transition: .2s opacity ease;
    }

    .linksContainer{
        display: block;
        width: 100%;
        transform: translateX(-200px);
        transition: .3s transform ease;
    }

    .mobileExpanded .linksContainer{
        transform: none;
    }

    .navigation .navLink{
        box-sizing: border-box;
        padding: 25px 20px;
        width: 100%;
        font-weight: bold;
        margin: 0;
        color: var(--color-blue);

        transition: .2s transform ease;
    }
    .navigation .navLink:last-child{
        border-bottom: none;
    }
    .contactWrapper{
        margin-top: 40px;
        padding: 20px 0;
        border-left: none;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .navigation .contactButton{
        background: var(--color-accent);
        padding: 20px 40px;
        border-radius: 8px;
        transform: translateY(100px);
        transition: .3s transform ease;
    }
    .mobileExpanded .contactButton{
        transform: none;
    }
    .navigation  .contactText{
        font-weight: bold;
        color: var(--color-white);
    }
    .navigation  .contactButton svg path{
        fill: white;
    }
    .navLink:active, .contactButton:active{
        transform: scale(.95);
    }
}
.fullWidthBanner{
    display: flex;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    background: var(--color-lightgray);
    position: relative;
}
.formWrapper{
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    padding: 120px 40px;
    padding-top: 0;
    flex-basis: 50%;
}
/*.formWrapper, .text{*/
    /*width: 50%;*/
/*}*/

.image{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    opacity: .4;
}

.text{
    width: 50%;
    padding: 40px;
    padding-bottom: 120px;
    margin-left: 40px;
    box-sizing: border-box;
}

.text p{
    margin: 50px 0;
}

.fullWidthBanner button{
    margin-top: 10px;
}
.formImageWrapper{
    display: flex;
    z-index: 1;
}
.formImageWrapper svg{
    height: 420px;
}

.contactFormContainer{
    background: white;
    padding: 40px;
    border-radius: 20px;
    width: 100%;
}

@media (max-width: 1280px) {
    .formWrapper{
        padding-left: 0px;
    }
    .text{
        margin-left: 0;
        padding-right: 10px;
    }
}

@media (max-width: 1024px) {
    .fullWidthBanner{
        flex-direction: column;
    }
    .formWrapper{
        padding-top: 60px;
        padding-left: 40px;
    }
    .formWrapper, .text{
        width: 100%;
    }
    .image{
        position: static;
        height: 50vh;
        width: 100%;
    }
    .text{
        padding: 120px 40px 40px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (max-width: 768px) {
    .text h2{
        text-align: center;
    }
    .fullWidthBanner{
        flex-direction: column;
    }
    .formWrapper{
        padding: 60px 20px;
        padding-top: 0;
    }
    .text{
        padding: 40px 20px 60px 20px;

    }
    .formWrapper{
        display: none;
    }

    .text p{
        margin: 20px 0;
    }
}
.button{
    display: none;
    background: none;
    border: none;
    padding: 10px;
    margin-top: 0;
    margin-right: -10px
}

.panel{
    background: white;
    width: 23px;
    height: 3px;
    border-radius: 10px;
    transition: .3s transform ease;
}

.panel:nth-child(2){
    margin: 5px 0;
    transition: none;
}

.button.isExpanded .panel:first-child{
    transform: rotate(45deg)  translateY(2px);
}
.button.isExpanded .panel:last-child{
    transform: rotate(-45deg) translateY(-2px);
}
.button.isExpanded .panel:nth-child(2){
    display: none;
}

@media (max-width: 768px) {
    .button{
        display: block;
    }

}
.priceOffer{
    padding: 60px;
    margin: 0 20px;
    background: var(--color-lightgray);
    width: 36%;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(96, 96, 96, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: .5s all ease;
    border: 1px solid transparent;
}

.priceOffer:hover{
    background: white;
    box-shadow: 0 30px 60px 0 rgba(96, 96, 96, 0.2);
    border-color: var(--color-lightgray);
}

.mainContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.iconContainer{
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    background: var(--color-white);
    border-radius: 100%;
}

.iconBg{
    position: absolute;
    left: -24px;
    right: -24px;
    top: -24px;
    bottom: -24px;
    opacity: .2;
    background-size: contain!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
}

.iconContainer svg{
    width: 100%;
}

.iconContainer svg path{
    fill: var(--color-accent);
}

.name{
    margin-top: 40px;
    color: var(--color-accent);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}

.priceContainer{
    width: 100%;
    padding: 24px 0 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 1px solid lightgray;
}

.price{
    font-size: 40px;
    line-height: 32px;
    color: var(--color-blue);
    font-weight: bold;
}

.duration{
    font-size: 16px;
    margin-left: 8px;
    color: var(--color-lightblue);
}

.separator{

}

.featuresContainer{
    margin-top: 30px;
    width: 100%;
}

.feature{
    font-weight: 300;
    margin: 20px 0;
    font-size: 16px;
    color: var(--color-lightblue);
}

.feature:first-child{
    margin-top: 0;
}

.priceOffer button{
    margin-top: 40px;
}

@media (max-width: 1280px) {
    .priceOffer{
        padding: 60px 30px;
    }

    .price{
        font-size: 36px;
        line-height: 28px;
    }
}

@media (max-width: 1024px) {
    .priceOffer{
        width: 32%;
    }
}

@media (max-width: 768px) {
    .priceOffer{
        margin: 0 20px;
        padding: 30px;
        background: white;
    }

    .priceOffer, .priceOffer:hover{
        border-color: var(--color-lightgray);
        box-shadow: none;
    }

    .name{
        margin-top: 30px;
    }

    .price{
        font-size: 30px;
        line-height: 24px;
    }

    .priceOffer button{
        margin-top: 10px;
    }

    .featuresContainer{
        margin-top: 20px;
    }

    .feature{
        margin: 10px 0;
    }

    .priceContainer{
        padding: 10px 0 20px;
    }
}

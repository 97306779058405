@font-face {
    font-family: 'Nunito';
    src: url(./assets/fonts/Nunito-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: url(./assets/fonts/Nunito-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url(./assets/fonts/Nunito-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url(./assets/fonts/Nunito-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url(./assets/fonts/Nunito-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url(./assets/fonts/Nunito-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

body {
    margin: 0;
    font-family: var(--default-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-black);
}

:root{
    --color-black: #252525;
    --color-white: #f4f4f4;
    --color-accent: #4b0732;
    --color-accent-light: #7f5171;
    --color-blue: #250a1c;
    --color-lightblue: #361e2e;
    --color-lightgray: #efefef;
    --color-gray: #ebebeb;
    --color-blueishgray: #f6f7fa;
    --color-green: #00b916;
    --default-navbar-height: 110px;
    --default-font-family: 'Nunito', 'Helvetica Neue', sans-serif;
}

*:focus{
    outline: none;
}

input,
textarea,
button,
select,
div,
a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.grecaptcha-badge { visibility: hidden; }
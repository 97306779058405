.button{
    border: 1px solid transparent;
    border-radius: 40px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: .2s transform ease!important;
    font-weight: 600;
    font-family: var(--default-font-family);
}

.white{
    background: transparent;
    color: var(--color-white);
    border-color: var(--color-white);
}

.white.filled{
    background: var(--color-white);
    color: var(--color-accent);
    border-color: transparent;
}

.purple{
    background: transparent;
    color: var(--color-accent);
    border-color: var(--color-accent);
}

.purple.filled{
    background: var(--color-accent);
    color: var(--color-white);
    border-color: transparent;
}


.button:active{
    transform: scale(.95);
}
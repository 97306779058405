.wrapper{
    position: relative;
    padding-top: 80px;
    padding-top: var(--default-navbar-height);
    height: 100vh;
    box-sizing: border-box;
    /*background: linear-gradient(125deg, #878787 0,#212121 64%,#000000 100%);*/
    /*background-size: 200% 200%;*/
    /*background: linear-gradient(0, #efefef 0,#9188fb 52%,#5f51fb 100%);*/
    /*background: linear-gradient(0, #a49dff 0,#5f51fb 100%);*/
    /*animation: gradient 5s ease infinite;*/
    position: relative;
    background: radial-gradient(ellipse at bottom, #350f28 0,#090a0f 100%);
}

.introContent{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}
.introUpper{
    margin-top: 90px;
    z-index: 1;

}

.buttonsContainer{
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.buttonsContainer button{
    margin: 0 10px;
}
.introCaption{
    font-size: 44px;
    color: var(--color-white);
    text-align: center;
    line-height: 72px;
    margin: 0;
}

.introSubtitle{
    color: var(--color-white);
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    width: 800px;
    max-width: 100%;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.68), 2px 0px 4px rgba(0, 0, 0, 0.6);
}

@keyframes gradient {
    0% {
        background-position: 50% 0;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 50% 0;
    }
}

@media (max-width: 768px) {
    .wrapper{
        padding-top: 86px;
        background-size: 300% 300%;
    }

    .introUpper{
        margin-top: 40px;
    }

    .introCaption{
        font-size: 32px;
        line-height: 42px;
    }

    .buttonsContainer{
        flex-wrap: wrap;
        margin: 10px 0;
    }

    .buttonsContainer button{
        margin-bottom: 20px;
    }
    .introSubtitle{
        width: auto;
    }
}
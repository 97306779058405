.featureItem{
    padding: 50px;
    color: var(--color-blue);
    border-radius: 5px;
    width: 360px;
    height: 460px;
    margin: 10px;
    box-sizing: border-box;
    background: white;
    border: 1px solid #efefef;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}
.iconWrapper{
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 100%;
    width: 100px;
    height: 100px;

}

.iconBg{
    position: absolute;
    border-radius: 100%;
    top: -10px;
    bottom: -10px;
    right: -10px;
    left: -10px;
    animation: gradient 5s linear infinite;
}

.iconWrapper svg{
    position: relative;
    width: 64px;
}

.iconWrapper svg path, .iconWrapper svg circle{
    fill: var(--color-white);
}

.featureItem:nth-child(1) .iconBg{
    background-image: linear-gradient(70deg, #b721ff 0%, #21c7fd 100%);
}
.featureItem:nth-child(2) .iconBg{
    background-image: linear-gradient(110deg, #009fff, #00c2f8, #00de80, #2af59d);
}
.featureItem:nth-child(3) .iconBg{
    background-image: linear-gradient(to right bottom, #eaea08, #ffbf00, #ff9109, #ff6133, #f52a4d);
}
.featureItem:nth-child(4) .iconBg{
    background-image: linear-gradient(120deg, #ff6133 0%, #b721ff 100%);
}


.title{
    font-size: 20px;
    margin: 0 0 18px 0;
}

.subtitle{
    font-size: 16px;
    line-height: 28px;
    color: var(--color-lightblue);
}

@media (max-width: 1580px) {
    .featureItem{
        flex-grow: 1;
        width: 45%;
        height: 380px;
    }
}
@media (max-width: 1024px) {
    .featureItem{
        height: 460px;
    }
}

@media (max-width: 768px) {
    .featureItem{
        margin: 20px;
        padding: 30px 20px;
        height: auto;
    }
}

@keyframes gradient {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

.promoBlockItem{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 60px;
}

.promoBlockItem img{
    width: 640px;
}

.promoBlockItem div ~ img, .promoBlockItem img ~ div{
    margin-left: 60px;
}

.promoBlockItem p{
    margin: 20px 0;
}

.promoBlockItem button{
    margin-top: 10px;
}

@media (max-width: 1280px) {
    .promoBlockItem img{
        width: 360px;
    }
}

@media (max-width: 768px) {
    .promoBlockItem button{
    }
    .promoBlockItem{
        flex-direction: column;
        align-items: center;
        margin-bottom: 120px;
        padding-bottom: 0;
    }
    .promoBlockItem:last-child{
        margin-bottom: 0;
    }
    .promoBlockItem:nth-child(even){
        flex-direction: column-reverse;
    }
    .promoBlockItem div ~ img, .promoBlockItem img ~ div{
        margin-left: 0;
    }
    .promoBlockItem > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .promoBlockItem * {
        text-align: left;
    }

    .promoBlockItem img{
        width: 100%;
        margin-bottom: 20px;
    }
}
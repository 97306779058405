.pricingBlock{

}

.captions{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.pricingBlock p{
    max-width: 840px;
    text-align: center;
    margin-top: 20px;
}

.offersContainer{
    display: flex;
    justify-content: center;
}

.offersContainer > div:nth-child(1) svg path{
    fill: #7fcb11;
}

.offersContainer > div:nth-child(2) svg path{
    fill: var(--color-accent);
}

.offersContainer > div:nth-child(3) svg path{
    fill: #e92460;
}

@media(max-width: 768px) {
    .captions {
        margin-bottom: 30px;
    }

    .offersNegativeMargin {
        margin: 0 -20px;
    }

    .offersContainer {
        box-sizing: border-box;
        padding: 0 20px;
    }
}
@media (max-width: 768px) {
    .container > div{
        scroll-snap-type: x mandatory;
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
    }

    .container > div::-webkit-scrollbar {
        display: none;
    }

    .container > div> div{
        scroll-snap-align: center;
        width: 100%;
        max-width: none;
        flex-shrink: 0;

        box-sizing: border-box;
    }

    .container > div> div:last-child{
        position: relative;
    }

    .container > div> div:first-child{
        margin-left: 0;
    }

    .container > div> div:last-child::after {
        position: absolute;
        content: '';
        right: -20px;
        width: 20px;
        height: 1px;
        top: 50%;
    }

    .sliderIndicators{
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .circle{
        width: 6px;
        height: 6px;
        margin: 4px;
        border-radius: 100%;
        background: var(--color-accent-light);
        transition: .3s all ease;
    }
    .circle.active{
        background: var(--color-accent);
    }
}

@media (min-width: 769px){
    .sliderIndicators{
        display: none;
    }
}
.title{
    margin: 0;
    font-size: 42px;
    line-height: 44px;
    font-weight: bold;
    color: var(--color-blue);
}

@media (max-width: 768px) {
    .title{
        font-size: 28px;
    }
}
.hidden{
    opacity: 0;
    pointer-events: none;
}

.wrapper{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}

.wrapper svg{
    width: 96px;
    height: auto;
}
.wrapper svg path{
    width: 120px;

    fill: var(--color-white);
}

.wrapper svg.loaderImage path{
    transition: .3s all ease;
    fill: transparent;
    stroke: var(--color-lightblue);
    stroke-linejoin: round;
    stroke-linecap: round;
    animation: strokeAnimation 3s infinite linear both;
    stroke-dasharray: 300px;
    stroke-dashoffset: 0;
    stroke-width: 2px;
}
.text{
    font-size: 12px;
    color: var(--color-lightblue);
    margin-top: 10px;
    font-weight: 300;
}
/*svg.loaderImage path{*/
    /*fill: red;*/
/*}*/
@keyframes strokeAnimation {
    0%{
        stroke-dashoffset: 480%;
    }
    100%{
        stroke-dashoffset: 0;
    }
}
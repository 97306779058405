.laptopWrapper{
    width: 640px;
    position: absolute;
    bottom: 20px;
    display: flex;
    box-shadow: 0 25px 20px -30px rgba(0, 0, 0, 0.79);
    animation: float 8s linear infinite;
    transition: .2s all ease;
}

.laptopWrapper svg{
    width: 100%;
}

.laptopWrapper.scrolled{
    bottom: -60px;
}

.laptopScreenContent{
    overflow: hidden;
    position: absolute;
    left: calc((100% - 100% / 1.474) / 2);
    top: calc((100% / 1.474 / 1.65 - 100% / 1.474 / 1.65 / 1.433) / 2);
    width: calc(100% / 1.474);
    padding-bottom: calc(100% / 1.474 / 1.65);
    height: 0;
    background: black;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
}

@media (max-width: 768px){
    .laptopWrapper{
        width: calc(100% - 40px);
    }

    .laptopWrapper.scrolled{
        bottom: -30px;
    }
}

@media (max-width: 768px) and (orientation: landscape){
    .laptopWrapper{
        display: none;
    }
}

@keyframes float {
    0% {
        transform: none;
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: none;
    }
}
.form{
    position: relative;
    width: 100%;
}

.controlsContainer{
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.statusMessageWrapper{
    margin-left: 20px;
}

.promoSelectWrapper{
    margin-bottom: 20px;
}

.controlsContainer button{
    margin-top: 0;
}

@media(max-width: 768px){
    .controlsContainer{
        display: block;
    }


    .controlsContainer button{
        width: 100%;
    }

    .statusMessageWrapper{
        margin-left: 0;
        margin-top: 20px;
    }
}
.app{

}

.greyBg{
    background: var(--color-lightgray);
}
.whiteBg{
    background: var(--color-white);

}
.padded{
    padding: 120px 0;
}

@media(max-width: 768px){
    .padded{
        padding: 60px 0;
    }
}
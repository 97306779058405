.slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px;
    color: var(--color-white);
    box-sizing: border-box;
    opacity: 0;

    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
    transition: .5s opacity ease;
}

.slide.active{
    opacity: 1;
}

.slide:nth-child(1){
    background-image: linear-gradient(70deg, #b721ff 0%, #21c7fd 100%);
}
.slide:nth-child(2){
    background-image: linear-gradient(to right bottom, #0874ea, #009fff, #00c2f8, #00ded0, #2af59d);
}
.slide:nth-child(3){
    background-image: linear-gradient(to right bottom, #eaea08, #ffbf00, #ff9109, #ff6133, #f52a4d);
}
.title{
    font-size: 32px;
    margin: 0 0 10px 0;
    transition: .5s transform linear;
    transform: translateY(-30px);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.slide.active .title{
    transform: none;
}
.subtitle{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
    transition: .3s transform linear;
    transform: scale(.4);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.slide.active .subtitle{
    transform: none;
}

@media (max-width: 1280px) {
    .slide{
        flex-grow: 1;
    }
}
@media (max-width: 768px) {
    .title{
        font-size: 16px;
        margin: 0 0 5px 0;
    }
    .subtitle{
        font-size: 12px;
        line-height: unset;
    }
    .slide{
        padding: 10px;
    }
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.contentWrapper{
    padding: 0 calc((100% - 1200px) / 2);
}

@media(max-width: 1280px){
    .contentWrapper{
        padding: 0 40px;
    }
}

@media(max-width: 768px){
    .contentWrapper{
        padding: 0 20px;
    }
}

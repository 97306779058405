.wrapper{
    display: flex;
    justify-content: center;
}
.featureList{
    display: flex;
    justify-content: flex-start;
    margin-top: 64px;
}

@media (max-width: 1580px) {
    .featureList{
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .wrapper{
        display: block;
        margin: 0 -20px;
        margin-top: 20px;
    }
    .featureList{
        flex-wrap: nowrap;
        margin-top: 0;
        padding: 0 20px;
        box-sizing: border-box;
    }
}